<template>
   <v-card>
          <v-card-text>
            <div id="chart">
        <apexchart type="radar" height="350" :options="chartOptions" :series="series"></apexchart>
      </div>
          </v-card-text>
        </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    apexchart: VueApexCharts
  },
  methods: {
    generateData (length) {
      const array = []
      for (let i = 0; i < length; i++) {
        array.push({ x: 'W' + (i + 1), y: Math.floor(Math.random() * 100) })
      }
      return array
    }
  },
  data () {
    return {
      series: [{
        name: 'Series 1',
        data: [20, 100, 40, 30, 50, 80, 33]
      }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'radar'
        },
        dataLabels: {
          enabled: true
        },
        plotOptions: {
          radar: {
            size: 140,
            polygons: {
              strokeColors: '#e9e9e9',
              fill: {
                colors: ['#f8f8f8', '#fff']
              }
            }
          }
        },
        title: {
          text: 'Radar with Polygon Fill'
        },
        colors: ['#FF4560'],
        markers: {
          size: 4,
          colors: ['#fff'],
          strokeColor: '#FF4560',
          strokeWidth: 2
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        },
        xaxis: {
          categories: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        },
        yaxis: {
          tickAmount: 7,
          labels: {
            formatter: function (val, i) {
              if (i % 2 === 0) {
                return val
              } else {
                return ''
              }
            }
          }
        }
      }
    }
  }
}
</script>

<style>

</style>
