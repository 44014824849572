<template>
   <v-card>
          <v-card-text>
            <div id="chart">
        <apexchart type="heatmap" height="350" :options="chartOptions" :series="series"></apexchart>
      </div>
          </v-card-text>
        </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    apexchart: VueApexCharts
  },
  methods: {
    generateData (length) {
      const array = []
      for (let i = 0; i < length; i++) {
        array.push({ x: 'W' + (i + 1), y: Math.floor(Math.random() * 100) })
      }
      return array
    }
  },
  data () {
    return {
      series: [{
        name: 'Metric1',
        data: this.generateData(18, {
          min: 0,
          max: 90
        })
      },
      {
        name: 'Metric2',
        data: this.generateData(18, {
          min: 0,
          max: 90
        })
      },
      {
        name: 'Metric3',
        data: this.generateData(18, {
          min: 0,
          max: 90
        })
      },
      {
        name: 'Metric4',
        data: this.generateData(18, {
          min: 0,
          max: 90
        })
      },
      {
        name: 'Metric5',
        data: this.generateData(18, {
          min: 0,
          max: 90
        })
      },
      {
        name: 'Metric6',
        data: this.generateData(18, {
          min: 0,
          max: 90
        })
      },
      {
        name: 'Metric7',
        data: this.generateData(18, {
          min: 0,
          max: 90
        })
      },
      {
        name: 'Metric8',
        data: this.generateData(18, {
          min: 0,
          max: 90
        })
      },
      {
        name: 'Metric9',
        data: this.generateData(18, {
          min: 0,
          max: 90
        })
      }
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'heatmap'
        },
        dataLabels: {
          enabled: false
        },
        colors: ['#008FFB'],
        title: {
          text: 'HeatMap Chart (Single color)'
        }
      }
    }
  }
}
</script>

<style>

</style>
