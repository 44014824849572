<template>
  <v-container fluid>
     <TitleBar :title="'วิเคราะห์เชิงลึก'" :subtitle="'0 รายงาน'">
      <template slot="action">
      </template>
    </TitleBar>
    <FilterBar>
      <template slot="left">
         <v-col cols="12" md="3" >
          <TextField :title="'ค้นหา'" append-icon="mdi-magnify" placeholder="ชื่อ,ผู้สร้าง,อื่นๆ แบบฟอร์ม"/>
         </v-col>
      </template>
      <template slot="right">
        <v-col cols="12" md="2" >
                <Select :title="'ประเภท'" :placeholder="'โปรดเลือก'"/>
              </v-col>
               <v-col cols="12" md="2" >
                 <Select :title="'สถานะ'" :placeholder="'โปรดเลือก'"/>
              </v-col>
               <v-col cols="12" md="2" >
                 <Select :title="'ประจำปี'" :placeholder="'โปรดเลือก'"/>
              </v-col>
      </template>
    </FilterBar>
    <v-row class="pl-5 pr-10">
      <v-col cols="12">
        <HeatMapCard/>
      </v-col>
      <v-col cols="12">
        <RadarChartCard/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TitleBar from '@/components/common/TitleBar'
import TextField from '@/components/common/TextField'
import Select from '@/components/common/Select'
import FilterBar from '@/components/common/FilterBar'
import HeatMapCard from '@/components/analytics/HeatmapCard'
import RadarChartCard from '@/components/analytics/RadarChartCard'
// import DataTable from '@/components/common/DataTable'

export default {
  components: {
    TitleBar,
    TextField,
    Select,
    FilterBar,
    HeatMapCard,
    RadarChartCard
    // DataTable
  },
  data () {
    return {
      headers: [
        { text: 'ชื่อ', value: 'name', class: 'primary lighten-5' },
        { text: 'ประเภท', value: 'type', class: 'primary lighten-5' },
        { text: 'ประจำปี', value: 'year', class: 'primary lighten-5' },
        { text: 'วันที่สร้าง', value: 'createdate', class: 'primary lighten-5' },
        { text: 'ผู้สร้าง', value: 'createby', class: 'primary lighten-5' },
        { text: 'จำนวนคนตอบ', value: 'total', class: 'primary lighten-5' },
        { text: 'สถานะ', value: 'status', class: 'primary lighten-5' },
        { text: 'Action', value: 'action', class: 'primary lighten-5' }
      ],
      loading: true
    }
  }
}
</script>

<style>

</style>
